import axios from '@/libs/axios'

class DataService {
    getAll(page) {
        return axios.get("/workShop?page="+page);
    }
    get(id) {
        return axios.get(`/workShop/${id}`);
    }
    getCaptains(){
        return axios.get("/captainsLkb");
    }
    getRegion() {
        return axios.get("/regions");
    }
    getComponents(){
        return axios.get('components');
    }
    editComponent(id, data){
        return axios.put(`/editworkshopComponent/${id}`, data);
    }
    create(data) {
        return axios.post("/workShop", data);
    }
    update(id, data) {
        return axios.put(`/workShop/${id}`, data);
    }
    delete(id) {
        return axios.delete(`/workShop/${id}`);
    }
    deleteAll() {
        return axios.delete(`/workShop`);
    }
    findByTitle(title) {
        return axios.get(`/workShop?title=${title}`);
    }
    changeStatus(id) {
        return axios.get(`/changeWorkShopStatus/${id}`)
    }
    changePassword(id) {
        return axios.get(`/changeWorkshopPassword/${id}`);
    }
}
export default new DataService();